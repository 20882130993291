import { AppTheme } from '../../types/theme';
import formLabel from '../mixins/formLabel';
import { css } from 'styled-components';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    return css`
        .control-label {
            ${formLabel(theme)};

            &.control-label--hidden {
                ${formLabel(theme)}
            }

            + .formio-choices {
                margin-top: 0;
            }

            &.field-required:after {
                color: ${sassVars.formElementLabelRequiredColor};
            }
        }

        .has-error .control-label {
            color: ${sassVars.formElementLabelColor};
        }
    `;
}
