import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import buttonSmall from './mixins/buttonSmall';
import textEllipsis from './mixins/textEllipsis';
import viewOnlyFieldsetColumnsLabel from './mixins/viewOnlyFieldsetColumnsLabel';
import viewOnlyDl from './mixins/viewOnlyDl';
import formControl from './components/formControl';
import helpBlock from './components/helpBlock';
import controlLabel from './components/controlLabel';
import formioFieldset from './components/formioFieldset';
import rowActionButtons from './components/rowActionButtons';
import formioEditGrid from './components/formioEditGrid';
import formioDataGrid from './components/formioDataGrid';
import formioDateTime from './components/formioDateTime';
import buttons from './components/buttons';
import checkRadioBox from '../checkRadioBox';
import select from './components/select';
import expertiseForm from './components/expertiseForm';
import expertiseTaskReportEditForm from './components/expertiseTaskReportEditForm';
import panel from './components/panel';
import formioTextArea from './components/formioTextArea';
import tables from './components/tables';
import columns from './components/columns';

export const StyledForm = withTheme(
    styled.div`
        width: 100%; // TODO: нет в ics
        .formio-form {
            counter-reset: panelCounter;
            ${({ theme }) => expertiseTaskReportEditForm(theme)}
            // TODO: нет в ics https://totsys.atlassian.net/browse/MEXP-259 https://totsys.atlassian.net/browse/MEXP-296
            > div > *:not(.panel):not(fieldset),
            > div > .panel > .panel-body > *:not(.panel):not(fieldset) {
                background: #ffffff;
                margin-bottom: 0;
            }

            > div > *:not(.panel):not(fieldset):not(.formio-component-columns) {
                padding: 0 23px 20px;
            }

            > div > .formio-component-columns {
                padding: 0 23px;
            }

            > div > *:not(.panel):not(fieldset) {
                &:first-child {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }

                &:last-child {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }

            > div > .panel > .panel-body > *:not(.panel):not(fieldset) {
                padding: 0 83px 20px;
            }

            > div > *:not(.panel):not(fieldset):first-child,
            > div > .panel + *:not(.panel):not(fieldset),
            > div > fieldset + *:not(.panel):not(fieldset) {
                padding-top: 20px;
            }

            > div > .panel {
                &:first-child {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }

                &:last-child {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }

            > div > .form-group {
                margin-top: 0;
            }

            // END TODO: нет в ics

            * {
                box-sizing: border-box;
            }

            //min-height: ${({ theme }) => theme.variables.sass.formioFormMinHeight}; // TODO: в ics есть, в mexp нет

            // TODO: Перенос в panel (или удаление) https://totsys.atlassian.net/browse/ICS-384
            > div {
                > .panel-default {
                    > .panel-body {
                        > .formio-component {
                            padding-left: 83px;
                            padding-right: 83px;
                        }
                        > .checkbox {
                            padding-left: 0px;
                            padding-right: 0px;
                        }
                    }
                }
            }
            // END TODO: Перенос в panel (или удаление)

            ${({ theme }) => formioTextArea(theme)}
            ${({ theme }) => tables(theme)}

            .tooltip {
                opacity: 1;
                left: 10px !important;
            }

            // Переносит на новую строку длинные варианты в селектах
            .formio-component-select {
                .choices {
                    .choices__list {
                        word-break: normal;
                    }

                    &[data-type='select-one'] {
                        .form-control {
                            height: auto;
                        }
                    }
                    .form-control .choices__list {
                        .choices__item {
                            word-break: normal;
                            height: auto;
                            max-width: 100%;

                            span {
                                white-space: normal;
                            }
                        }
                    }
                }
            }

            .form-view-only {
                input[type='number'] {
                    -moz-appearance: textfield;
                }

                textarea {
                    resize: none;
                }

                .radio label,
                .checkbox label {
                    padding-left: 0;
                    min-height: inherit;
                    display: inline;
                    cursor: text;
                }

                .radio-inline {
                    padding-left: 0;
                }

                .radio-inline label {
                    font-weight: normal;
                }

                input[type='checkbox'][disabled],
                input[type='radio'][disabled] {
                    display: none;
                }

                input[type='checkbox'] + span,
                input[type='radio'] + span {
                    display: none;
                }

                input[type='checkbox']:checked + span,
                input[type='radio']:checked + span {
                    display: inline-block;
                }

                input[type='checkbox']:checked + span,
                input[type='radio']:checked + span {
                    display: inline;
                }

                .choices[data-type*='select-one'] .choices__button,
                .choices[data-type*='select-one']:after {
                    display: none;
                }

                .field-required:after {
                    display: none;
                }

                .form-control[disabled],
                .choices.is-disabled .choices__item {
                    cursor: text;
                }

                .formio-component-datetime .input-group-addon {
                    display: none;
                }

                .fileSelector {
                    display: none;
                }

                .panel .formio-component-fieldset {
                    width: ${({ theme }) => theme.variables.sass.viewOnlyComponentFieldsetWidth};
                    margin-top: 0;
                    margin-bottom: ${({ theme }) => theme.variables.sass.viewOnlyComponentFieldsetMarginBottom};

                    > .card-body {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        padding: ${({ theme }) => theme.variables.sass.viewOnlyComponentFieldsetCardBodyPadding};

                        dl {
                            &:last-child,
                            &:nth-last-child(2) {
                                margin-bottom: ${({ theme }) =>
                                    theme.variables.sass.viewOnlyComponentFieldsetCardBodyDlLastChildMarginBottom};
                            }
                        }
                    }

                    // fieldset-columns - кастомный класс для блока с документами
                    &.fieldset-columns {
                        > .card-body {
                            display: block;
                            border: ${({ theme }) => theme.variables.sass.viewOnlyFieldsetColumnsBorder};
                            padding: ${({ theme }) => theme.variables.sass.viewOnlyFieldsetColumnsPadding};

                            .table-secondary-header {
                                margin-top: ${({ theme }) =>
                                    theme.variables.sass.viewOnlyTableSecondaryHeaderMarginTop};
                                margin-bottom: ${({ theme }) =>
                                    theme.variables.sass.viewOnlyTableSecondaryHeaderMarginBottom};
                            }

                            hr {
                                margin-top: ${({ theme }) =>
                                    theme.variables.sass.viewOnlyFieldsetColumnsMarginHorizontal};
                                margin-bottom: ${({ theme }) =>
                                    theme.variables.sass.viewOnlyFieldsetColumnsMarginHorizontal};
                            }

                            .formio-component {
                                p {
                                    margin: ${({ theme }) => theme.variables.sass.viewOnlyFieldsetColumnsPMargin};
                                }
                            }
                        }

                        .formio-component-columns {
                            .col {
                                padding-top: ${({ theme }) =>
                                    theme.variables.sass.viewOnlyFieldsetColumnsFormioComponentColumnsPaddingTop};

                                dl {
                                    width: ${({ theme }) =>
                                        theme.variables.sass.viewOnlyFieldsetColumnsFormioComponentColumnsDlWidth};
                                    float: ${({ theme }) =>
                                        theme.variables.sass.viewOnlyFieldsetColumnsFormioComponentColumnsDlFloat};

                                    > dd {
                                        ${({ theme }) => viewOnlyFieldsetColumnsLabel(theme)}
                                    }
                                }
                            }

                            .control-label {
                                ${({ theme }) => viewOnlyFieldsetColumnsLabel(theme)}
                            }

                            .formio-component-file {
                                > div:not(.help-block):last-child {
                                    margin-bottom: ${({ theme }) =>
                                        theme.variables.sass
                                            .viewOnlyFieldsetColumnsFormioComponentColumnsFormioComponentFileMarginBottom};
                                }
                            }
                        }
                    }
                }

                .formio-component-fieldset {
                    width: ${({ theme }) => theme.variables.sass.viewOnlyComponentFieldsetWidth};

                    .formio-component-fieldset .card-body {
                        padding: ${({ theme }) => theme.variables.sass.viewOnlyComponentFieldsetCardBodyPadding};
                    }

                    > .card-body {
                        &:after {
                            content: '';
                            display: block;
                            clear: both;
                        }

                        dl {
                            ${({ theme }) => viewOnlyDl(theme)}

                            &:last-child,
                            &:nth-last-child(2) {
                                margin-bottom: ${({ theme }) =>
                                    theme.variables.sass.viewOnlyComponentFieldsetCardBodyDlLastChildMarginBottom};
                            }
                        }
                    }
                }

                .formio-component-columns {
                    flex-basis: ${({ theme }) => theme.variables.sass.viewOnlyFormioComponentColumnsWidth};
                    width: ${({ theme }) => theme.variables.sass.viewOnlyFormioComponentColumnsWidth};
                }

                .formio-component-currency {
                    .input-group {
                        width: min-content;
                    }

                    .input-group-addon {
                        background: transparent;
                        border: none;
                    }
                }

                .formio-component-file {
                    .list-group-item .row .col-md-1 {
                        display: none;
                    }

                    span.glyphicon {
                        display: none;
                    }
                }

                .choices.is-disabled .choices__inner,
                .choices.is-disabled .choices__input {
                    background: transparent;
                    cursor: text;
                }

                .formio-component-textarea img {
                    max-width: 100%;
                    height: auto !important;
                }

                .panel-default {
                    .card-body .panel-body {
                        padding-top: ${({ theme }) => theme.variables.sass.viewOnlyPanelDefaultPanelBodyPaddingTop};
                        padding-bottom: ${({ theme }) =>
                            theme.variables.sass.viewOnlyPanelDefaultPanelBodyPaddingBottom};
                    }

                    > .panel-body {
                        .panel-default:last-child > .panel-body {
                            padding-bottom: ${({ theme }) =>
                                theme.variables.sass.viewOnlyPanelDefaultLastChildPanelBodyPaddingBottom};
                        }

                        &.card-body {
                            dl {
                                ${({ theme }) => viewOnlyDl(theme)}
                            }
                        }

                        .panel-body.card-body {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-wrap: wrap;

                            &[hidden] {
                                display: none;
                            }

                            dl {
                                ${({ theme }) => viewOnlyDl(theme)}
                            }

                            .formio-component-columns dl {
                                flex-basis: ${({ theme }) => theme.variables.sass.viewOnlyFormioComponentColumnsWidth};
                                width: ${({ theme }) => theme.variables.sass.viewOnlyFormioComponentColumnsWidth};
                            }
                        }
                    }
                }

                .formio-component-datagrid {
                    width: ${({ theme }) => theme.variables.sass.viewOnlyDatagridWidth};
                    margin-bottom: ${({ theme }) => theme.variables.sass.viewOnlyDatagridMarginBottom};

                    .datagrid-table {
                        dl {
                            margin-bottom: 0 !important;
                        }

                        th {
                            padding-top: ${({ theme }) => theme.variables.sass.viewOnlyDatagridThPaddingTop} !important;
                        }

                        td {
                            overflow: hidden;
                            max-width: 0;
                        }

                        th,
                        td {
                            &:not(:first-child) {
                                padding-left: ${({ theme }) =>
                                    theme.variables.sass.viewOnlyDatagridCellPaddingLeft} !important;
                            }
                        }

                        tbody tr:last-child td {
                            padding-bottom: ${({ theme }) =>
                                theme.variables.sass.viewOnlyDatagridLastTrPaddingBottom} !important;
                        }
                    }
                }
            }

            .formio-component table {
                width: 100%;
                border: none;
                margin-bottom: 20px;
            }

            .formio-component table tr {
                border: none;
                border-bottom: 1px solid #e5e5e5;
            }

            .formio-component table tr th {
                padding: 5px 14px;
                font-weight: 600;
                border-color: #e5e5e5;
            }

            .formio-component table tr td {
                padding: 5px 14px;
                border-color: #e5e5e5;
            }

            .formio-component table[border='0'] tr {
                border-color: transparent;
            }

            .formio-component table[border='0'] tr td,
            .formio-component table[border='0'] tr th {
                border-color: transparent;
                padding: 10px 0;
                vertical-align: top;
            }

            .formio-component table[border='0'] tr p {
                margin: 0;
            }

            .notice-label {
                background: #5f4985;
                font-size: 14px;
                display: inline-block;
                padding: 0 10px 2px;
                line-height: 1.5;
                color: #fff;
                margin-left: 10px;
            }

            ${({ theme }) => panel(theme)}
            ${({ theme }) => columns(theme)}

            .loader-wrapper {
                background-color: ${({ theme }) => theme.variables.sass.formioLoaderWrapper};
            }

            .formio-loader,
            .loader {
                border-top-color: ${({ theme }) => theme.variables.sass.formioLoaderBorderColor};
            }

            ${({ theme }) => formControl(theme)}

            // иконки (или текст) у полей, например, у календаря
            .input-group-addon {
                border-radius: 0 ${({ theme }) => theme.variables.sass.inputBorderRadiusSize} 0 0;
                border: ${({ theme }) => theme.variables.sass.inputBorder};
                border-width: ${({ theme }) => theme.variables.sass.inputBorderWidth};
                background: ${({ theme }) => theme.variables.sass.inputBackgroundColor};
                transition: ${({ theme }) => theme.variables.formio.input.iconTransition};
                color: ${({ theme }) => theme.variables.sass.inputAddonIconColor};

                .input-group-text {
                    color: ${({ theme }) => theme.variables.sass.inputAddonTextColor};
                    font-size: ${({ theme }) => theme.variables.sass.inputAddonTextFontSize};
                    line-height: ${({ theme }) => theme.variables.sass.inputAddonTextLineHeight};
                }
            }

            // иконка (или текст) перед полем, паддинги уже заданы для нее, полю не нужны
            .input-group-prepend + .form-control {
                padding-left: 0;
            }

            ${({ theme }) => helpBlock(theme)}

            .has-error {
                // т.к. рядом с ошибкой может быть блок с поясняющим текстом (пр: Возрастная категория)
                .help-block {
                    color: ${({ theme }) => theme.variables.sass.inputHelpBlockColor};
                }

                .form-control,
                .choices__inner {
                    border: 1px solid ${({ theme }) => theme.variables.sass.inputErrorBorderColor};
                    box-shadow: none;

                    &:focus {
                        border-color: ${({ theme }) => theme.variables.sass.inputErrorBorderColor};
                        box-shadow: ${({ theme }) => theme.variables.sass.inputFocusBoxShadow};
                    }
                }

                .choices__inner {
                    .form-control {
                        border-color: ${({ theme }) => theme.variables.sass.inputBorderColor};

                        &:focus {
                            border-color: ${({ theme }) => theme.variables.sass.inputFocusBorderColor};
                        }
                    }
                }

                .input-group-addon {
                    background-color: ${({ theme }) => theme.variables.sass.inputBackgroundColor};
                    border-color: ${({ theme }) => theme.variables.sass.inputErrorBorderColor};
                    color: ${({ theme }) => theme.variables.sass.inputAddonIconColor};

                    .input-group-text {
                        color: ${({ theme }) => theme.variables.sass.inputAddonTextColor};
                    }
                }
            }

            .formio-table-min-width-input-70px input {
                min-width: 70px;
            }

            .formio-table-min-width-input-100px input {
                min-width: 100px;
            }

            .formio-table-min-width-input-200px input {
                min-width: 200px;
            }

            .help-block + .formio-errors {
                order: 4;
            }

            .formio-edit-grid-min-width-100px .col-sm-2 {
                min-width: 100px;
            }

            .formio-edit-grid-min-width-150px .col-sm-2 {
                min-width: 150px;
            }

            .formio-edit-grid-min-width-200px .col-sm-2 {
                min-width: 200px;
            }

            .formio-edit-grid-min-width-250px .col-sm-2 {
                min-width: 250px;
            }

            .formio-edit-grid-min-width-300px .col-sm-2 {
                min-width: 300px;
            }

            .formio-errors .help-block,
            .bg-error {
                color: ${({ theme }) => theme.variables.sass.inputErrorHelpBlockColor};
            }

            .form-group {
                margin-top: ${({ theme }) => theme.variables.sass.formGroupMarginTop};
                margin-bottom: ${({ theme }) => theme.variables.sass.formGroupMarginBottom};
            }
            
            .form-group:last-of-type .is-open  {
                margin-bottom: 175px;
            }

            .field-required:after {
                content: ' *';
                color: ${({ theme }) => theme.variables.sass.formElementLabelRequiredColor} !important;
            }

            ${({ theme }) => controlLabel(theme)}

            ${({ theme }) => formioFieldset(theme)}

            // При возникновении ошибки при сохранении Edit Grid добавляет класс .alert-danger к .form-group
            // Так же замечено при валидации всей формы onSubmit (пример: Создание услуги)
            .alert-danger {
                background: transparent;
                padding: 0;
                border: none;

                .control-label {
                    color: ${({ theme }) => theme.variables.sass.formElementLabelColor};
                }
            }

            .has-feedback .form-control {
                padding-right: ${({ theme }) =>
                    theme.variables.sass
                        .inputPaddingHorizontal} !important; // переопределяем, т.к. formio всем компонентам добавляет класс hasFeedback
            }

            ${({ theme }) => rowActionButtons(theme)}

            //Formio для Text Field с Multiple Values (Группы текстовых полей) всегда рисует уродливую обертку в виде таблицы
            .formio-component-textfield.formio-component-multiple {
                table {
                    tr,
                    td {
                        border-width: 0;
                    }

                    td {
                        padding: 0;
                    }

                    .formio-button-add-row {
                        float: right;
                        margin-right: ${({ theme }) => theme.variables.sass.formioTextfieldMultipleRemoveColumnWidth};
                        margin-top: ${({ theme }) => theme.variables.sass.formioTextfieldMultipleButtonAddRowMarginTop};
                        box-shadow: none;

                        i {
                            display: none;
                        }
                    }

                    .formio-remove-column {
                        padding: ${({ theme }) =>
                                theme.variables.sass.formioTextfieldMultipleRemoveColumnPaddingVertical}
                            ${({ theme }) => theme.variables.sass.formioTextfieldMultipleRemoveColumnPaddingHorizontal};
                    }

                    .form-control {
                        margin-bottom: ${({ theme }) =>
                            theme.variables.sass.formioTextfieldMultipleFormControlMarginBottom};
                    }

                    // У полей особые отступы, поэтому блок с ошибкой нужно поднять выше
                    .formio-errors .help-block {
                        margin-top: (
                            ${({ theme }) => theme.variables.sass.inputErrorHelpBlockOffsetTop}
                                ${({ theme }) => theme.variables.sass.formioTextfieldMultipleFormControlMarginBottom}
                        );
                    }
                }
            }

            .btn:not(.btn-md):not(.btn-lg) {
                font-size: ${({ theme }) => theme.variables.sass.btnFontSizeXs};
            }

            .btn-primary,
            .btn-default {
                &:not(.btn-md):not(.btn-lg):not(.formio-button-remove-row) {
                    ${({ theme }) => buttonSmall(theme)}
                }

                min-width: ${({ theme }) => theme.variables.sass.formioBtnWidth};
            }

            ${({ theme }) => formioEditGrid(theme)}

            ${({ theme }) => formioDataGrid(theme)}
            
            ${({ theme }) => formioDateTime(theme)}

            ${({ theme }) => checkRadioBox(theme)}

            ${({ theme }) => select(theme)}

            // кастомный класс для блока с документами (разделителями служат hr) в форме заявки
            .fieldset-columns {
                .form-group {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                .formio-component-columns {
                    display: flex;

                    &:not(:first-child) {
                        &.columns-no-padding-top {
                            .col:not(:first-child) {
                                padding-top: 0;
                            }
                        }

                        .col:not(:first-child) {
                            display: flex;
                            align-items: flex-start;
                            padding-top: ${({ theme }) => theme.variables.sass.formElementLabelMarginBottom} +
                                ${({ theme }) => theme.variables.sass.formElementLabelLineHeight} + 4px;
                        }
                    }
                }

                .formio-component-file {
                    .list-group {
                        margin: 0;
                    }

                    .fileSelector {
                        margin-bottom: 0;
                    }
                }

                .control-label:last-child {
                    margin-bottom: 0;
                }

                .card-body {
                    padding-bottom: ${({ theme }) => theme.variables.sass.formioSubgroupPaddingVertical} !important;
                }
            }

            // еще более кастомный класс для формы создания/редактирования услуги
            .formio-component-aliasColumns {
                display: flex;

                .col:not(:first-child) {
                    display: flex;
                    align-items: flex-end;

                    .formio-component-button {
                        width: 100%;

                        .btn {
                            ${({ theme }) => buttonSmall(theme)}
                        }
                    }
                }
            }

            ${({ theme }) => buttons(theme)}

            .formio-component-file {
                min-height: ${({ theme }) => theme.variables.sass.fileInputHeight};

                &.has-error {
                    .fileSelector {
                        border-bottom: ${({ theme }) => theme.variables.sass.formElementBorderWidth}
                            ${({ theme }) => theme.variables.sass.inputBorderStyle}
                            ${({ theme }) => theme.variables.sass.inputErrorBorderColor} !important;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    > .help-block {
                        margin-top: ${({ theme }) => theme.variables.sass.fileHasErrorDescriptionMarginTop};
                    }
                }

                &:not(.formio-component-multiple) {
                    .list-group-striped {
                        .list-item {
                            background-color: ${({ theme }) => theme.variables.sass.inputBackgroundColor};
                            padding-left: ${({ theme }) => theme.variables.sass.inputPaddingHorizontal};
                            padding-right: ${({ theme }) => theme.variables.sass.inputPaddingHorizontal};
                            border-radius: ${({ theme }) => theme.variables.sass.fileInputSelectorBorderRadius};
                        }
                    }
                }

                &.formio-disabled-input {
                    input[type='file'] {
                        pointer-events: none;
                    }

                    .fileSelector {
                        display: none;
                    }

                    .list-group-striped .list-item {
                        .file-list__block-file-link {
                            flex-basis: ${({ theme }) => theme.variables.sass.fileListBlockFileDisabledLinkBasis};
                            max-width: ${({ theme }) => theme.variables.sass.fileListBlockFileDisabledLinkBasis};
                        }

                        .file-list__file-size {
                            text-align: ${({ theme }) => theme.variables.sass.fileListBlockFileDisabledLinkSizeAlign};
                        }

                        .file-list__file-remove {
                            flex-basis: ${({ theme }) => theme.variables.sass.fileListBlockFileDisabledRemoveBasis};
                            max-width: ${({ theme }) => theme.variables.sass.fileListBlockFileDisabledRemoveBasis};
                        }
                    }
                }

                input[type='file'] {
                    cursor: pointer;
                    width: 100%;
                    height: ${({ theme }) => theme.variables.sass.fileInputHeight};
                    overflow: hidden;
                    padding: 0;
                }

                .fileSelector {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: ${({ theme }) => theme.variables.sass.fileInputHeight};
                    background-color: ${({ theme }) => theme.variables.sass.inputBackgroundColor};
                    padding: ${({ theme }) => theme.variables.sass.fileInputSelectorPadding} !important;
                    border-radius: ${({ theme }) => theme.variables.sass.fileInputSelectorBorderRadius};
                    border: ${({ theme }) => theme.variables.sass.fileInputSelectorBorder};
                    text-align: center;
                    color: ${({ theme }) => theme.variables.sass.fileInputSelectorTextColor};
                    font-size: ${({ theme }) => theme.variables.sass.fileInputSelectorTextSize};
                    line-height: ${({ theme }) => theme.variables.sass.fileInputSelectorLineHeight};
                    letter-spacing: ${({ theme }) => theme.variables.sass.fileInputSelectorLetterSpacing};
                    transition: ${({ theme }) => theme.variables.sass.fileInputSelectorTransition};

                    &.fileDragOver {
                        border: ${({ theme }) => theme.variables.sass.formElementBorderWidth}
                            ${({ theme }) => theme.variables.sass.fileDragBorderStyle}
                            ${({ theme }) => theme.variables.sass.fileDragBorderColor} !important;
                    }

                    > i {
                        font-family: ${({ theme }) => theme.variables.sass.fontFamilyGlyphIcons};
                        font-size: ${({ theme }) => theme.variables.sass.fileInputSelectorIconSize};
                        color: ${({ theme }) => theme.palette.primary.main};
                        margin-right: ${({ theme }) => theme.variables.sass.fileInputSelectorIconMarginRight};

                        &:before {
                            content: '${({ theme }) => theme.variables.sass.fileInputSelectorIconContent}';
                        }
                    }

                    > a {
                        margin-left: ${({ theme }) => theme.variables.sass.fileInputSelectorLinkMarginLeft};
                    }
                }

                .file {
                    margin: 0;
                }

                .list-group-striped,
                .file {
                    position: relative;
                    margin: 0;

                    .list-group-header {
                        display: none;
                    }

                    .list-group-item {
                        border: none;
                        padding: 0;
                    }

                    .list-item {
                        display: flex;
                        height: ${({ theme }) => theme.variables.sass.inputHeight};
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: ${({ theme }) => theme.variables.sass.fileListItemBorderBottomWidth}
                            ${({ theme }) => theme.variables.sass.fileListItemBorderBottomStyle}
                            ${({ theme }) => theme.variables.sass.fileListItemBorderBottomColor};
                        font-size: ${({ theme }) => theme.variables.sass.fileListItemFontSize};
                        color: ${({ theme }) => theme.variables.sass.fileListLinkColor};

                        .file-list__block-file-link {
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            flex-basis: ${({ theme }) => theme.variables.sass.fileListBlockFileLinkBasis};
                            max-width: ${({ theme }) => theme.variables.sass.fileListBlockFileLinkBasis};
                            color: ${({ theme }) => theme.variables.sass.fileListLinkColor};
                            transition: ${({ theme }) => theme.variables.sass.fileListTransition};

                            &:hover,
                            &:hover .file-list__file-name {
                                color: ${({ theme }) => theme.variables.sass.hoverColor};
                            }
                        }

                        .file-list__file-name {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            flex-basis: ${({ theme }) => theme.variables.sass.fileListBlockFileLinkNameBasis};
                            max-width: ${({ theme }) => theme.variables.sass.fileListBlockFileLinkNameBasis};
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            line-clamp: 1;
                            box-orient: vertical;
                            margin-right: auto;
                            color: ${({ theme }) => theme.variables.sass.fileListNameColor};
                        }

                        .file-list__file-ext {
                            ${() => textEllipsis()}
                            flex-basis: ${({ theme }) => theme.variables.sass.fileListBlockFileLinkExtBasis};
                            max-width: ${({ theme }) => theme.variables.sass.fileListBlockFileLinkExtBasis};
                        }

                        .file-list__file-size {
                            ${() => textEllipsis()}
                            flex-basis: ${({ theme }) => theme.variables.sass.fileListBlockFileLinkSizeBasis};
                            max-width: ${({ theme }) => theme.variables.sass.fileListBlockFileLinkSizeBasis};
                        }

                        .file-list__file-remove {
                            display: flex;
                            flex-basis: ${({ theme }) => theme.variables.sass.fileListBlockFileRemoveBasis};
                            max-width: ${({ theme }) => theme.variables.sass.fileListBlockFileRemoveBasis};

                            > i {
                                font-size: ${({ theme }) => theme.variables.sass.fileListBlockFileRemoveIconSize};
                                cursor: pointer;
                                color: ${({ theme }) => theme.variables.sass.fileListIconColor};
                                transition: ${({ theme }) => theme.variables.sass.fileListTransition};

                                &:hover {
                                    color: ${({ theme }) => theme.variables.sass.hoverColor};
                                }
                            }
                        }
                    }
                }

                i.fa.fa-remove:after {
                    content: '\\f00d' !important;
                }

                .progress-block {
                    position: absolute;
                    bottom: 0;
                    width: ${({ theme }) => theme.variables.sass.fileProgressWidth};
                    background-color: ${({ theme }) => theme.variables.sass.fileProgressBlockBackgroundColor};
                    height: ${({ theme }) => theme.variables.sass.fileProgressHeight};
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: ${({ theme }) => theme.variables.sass.fileProgressFontSize};
                    line-height: ${({ theme }) => theme.variables.sass.fileProgressLineHeight};
                    color: ${({ theme }) => theme.variables.sass.fileProgressColor};

                    > [class^='bg-'] {
                        padding-left: ${({ theme }) => theme.variables.sass.fileProgressPaddingLeft};
                        border-radius: ${({ theme }) => theme.variables.sass.fileProgressRadius};
                    }

                    .bg-info {
                        background-color: ${({ theme }) => theme.variables.sass.fileProgressInfoBackgroundColor};
                    }

                    .bg-error {
                        background-color: ${({ theme }) => theme.variables.sass.fileProgressErrorBackgroundColor};
                    }

                    .progress {
                        height: ${({ theme }) => theme.variables.sass.fileProgressHeight};
                        margin-bottom: ${({ theme }) => theme.variables.sass.fileProgressMarginBottom};
                        background-color: ${({ theme }) => theme.variables.sass.fileProgressBackgroundColor};
                        border-radius: ${({ theme }) => theme.variables.sass.fileProgressRadius};
                    }

                    .progress-bar {
                        position: relative;
                        background-color: ${({ theme }) => theme.variables.sass.fileProgressBarBackgroundColor};
                        font-size: ${({ theme }) => theme.variables.sass.fileProgressFontSize};
                        line-height: ${({ theme }) => theme.variables.sass.fileProgressLineHeight};
                        box-shadow: ${({ theme }) => theme.variables.sass.fileProgressBarBoxShadow};
                    }

                    .lines {
                        position: absolute;
                        top: 0;
                        width: ${({ theme }) => theme.variables.sass.fileProgressLinesWidth};
                        background: ${({ theme }) => theme.variables.sass.fileProgressLinesBackground};
                        height: ${({ theme }) => theme.variables.sass.fileProgressLinesHeight};
                        overflow: hidden;
                        animation: moveBars 1s linear infinite;
                    }
                }

                /* ANIMATIONS */
                @keyframes moveBars {
                    100% {
                        margin-left: -180px;
                    }
                }
                @-webkit-keyframes moveBars {
                    100% {
                        margin-left: -180px;
                    }
                }
                @-moz-keyframes moveBars {
                    100% {
                        margin-left: -180px;
                    }
                }
                @-ms-keyframes moveBars {
                    100% {
                        margin-left: -180px;
                    }
                }
                @-o-keyframes moveBars {
                    100% {
                        margin-left: -180px;
                    }
                }
            }

            ${({ theme }) => expertiseForm(theme)}
        }
    `,
);
