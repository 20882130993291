import React from 'react';
import FormioForm from './FormioForm';
import { WebForm } from 'formiojs/WebForm';
import { FormApi } from '../api/FormApi';
import { observer } from 'mobx-react';
import { Box, CircularProgress, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import { FormModel, FullSubmission } from '../models/FormModel';
import { observable } from 'mobx';
import { FormioFormApi } from '../api/FormioFormApi';
import { defaultFormsLocale } from '../constants/intl';

const LoaderBox = withTheme(
    styled(Box)`
        text-align: center;
    `,
);

export type FormProps = {
    form: FormModel;
    locale: string; // Язык пользователя (выбранный в модуле).
    lang?: string; // Язык формы. Для текстового вида === locale. Иначе используется defaultFormsLocale.
    onFormReady?: (instance: FormApi) => void;
    onFormChange?: (instance: WebForm) => void;
    readOnly?: boolean;
    multiLang?: boolean; // Передается только в FormView. Для редактирования мультиязычной формы отдельный компонент - MultiLangFormEdit.tsx
    className?: string;
    setFormIsChanged?: (formIsChanged: boolean) => void;
};

@observer
class Form extends React.Component<FormProps, {}> {
    @observable formApi?: FormioFormApi;

    static defaultProps: Partial<FormProps> = {
        lang: defaultFormsLocale,
    };

    getLang = (): string => {
        return this.props.lang || defaultFormsLocale;
    };

    getReadOnly = (): boolean => {
        const { readOnly } = this.props;
        return !!readOnly;
    };

    getSubmission = (): FullSubmission => {
        const { form } = this.props;
        this.saveSubmission(form);
        return form.getFullSubmission();
    };

    saveSubmission = (form: FormModel): void => {
        if (!this.getReadOnly() && this.formApi) {
            form.setLangSubmission(this.getLang(), this.formApi.getSubmission());
        }
    };

    onFormReady = (form: FormioFormApi): void => {
        const { onFormReady } = this.props;
        this.formApi = form;
        const api = new FormApi(this, form);
        onFormReady && onFormReady(api as FormApi);
    };

    setStartSubmission = (): void => {
        const { form } = this.props;
        form.setStartSubmission();
    }

    setSubmissionFromStartSubmission = (): void => {
        const { form } = this.props;
        form.setSubmissionFromStartSubmission();
    }

    render(): JSX.Element {
        const { form, locale, onFormChange, readOnly, multiLang, className, setFormIsChanged } = this.props;
        const formioProps = {
            form: form.form,
            submission: multiLang ? form.getLangSubmission(locale) : form.getSubmission(),
            i18n: form.i18n,
            locale: locale,
            onFormReady: this.onFormReady,
            onFormChange: onFormChange,
            readOnly: readOnly,
            className: className,
            setFormIsChanged: setFormIsChanged,
        };

        return (
            <React.Fragment>
                {form.loaded ? (
                    <FormioForm {...formioProps} />
                ) : (
                    <LoaderBox>
                        <CircularProgress />
                    </LoaderBox>
                )}
            </React.Fragment>
        );
    }
}

export default Form;
